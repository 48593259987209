export enum PromotionBannerTestIds {
  empty = 'PromotionEmpty',
  title = 'PromotionTitle',
  asLink = 'PromotionAsLink',
  choice = 'PromotionChoiceOptions',
  content = 'PromotionContent',
  main = 'PromotionBanner',
  loading = 'PromotionBannerLoading',
}

export enum PromotionBannerErrors {
  noCurrentData = 'PromotionBanner: no current data',
  responseNotOk = 'PromotionBanner: fetch ContentSnippet response not ok',
  noUserData = 'PromotionBanner: Warn getting user information - fallback to anonymous',
  errorAddingToCart = 'PromotionBanner: Error adding code to cart',
}
